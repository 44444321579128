<template>
 <div>
  <b-card bg-variant="light">
    <div v-if="showSelection">
      <user-select @selectedItems="setId" />
    </div>
    <b-form-group
      label-cols-lg="3"
      label="Profile"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Nickname:"
        label-for="nested-street"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-street" v-model="attr_copy['nickname']"></b-form-input>
      </b-form-group>

      <b-form-group
        label="Alias ID:"
        label-for="nested-city"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-city" v-model="attr_copy['unique_alias']"></b-form-input>
      </b-form-group>

      <b-form-group
        label="Mobile:"
        label-for="nested-state"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-state" v-model="attr_copy['mobile']"></b-form-input>
      </b-form-group>

      <b-form-group
        label="Location:"
        label-for="nested-country"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-country" v-model="attr_copy['location']"></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-row>
      <b-col>
        <b-button block variant="primary" @click="callApi">Add Product Item</b-button>
      </b-col>
      <b-col>
        <b-button block variant="primary" @click="callCancel">Cancel</b-button>
      </b-col>
    </b-row>
   </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import UserSelect from './UserSelect.vue'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'

export default {
  components: {
    UserSelect
  },
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    }
  },
  data () {
    return {
      attr_copy: {},
      attr_orig: {},
    }
  },
  computed: {
    showSelection() {
      return !(this.attr_copy['user-id']?.length)
    }
  },
  methods: {
    setId(items) {
      this.attr_copy['user-id'] = items[0].id
    },
    callApi () {
     let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.post (
        'api/admin/v1/profiles', {
          ...attr_params
        }
      ).then(response => {
        if( response.data) {
          this.$emit('takeAction')
        }
        if(this.reroute) {
          this.$router.go(-1)
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callCancel() {
      this.$emit('noAction')
      if(this.reroute) {
        this.$router.go(-1)
      }
    }
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_copy = {...this.ams_template.attributes}
    }
    else {
      const included_obj = amsSchema.find( 
        el =>  
        el.type === 'profiles'
      )
      this.attr_copy = {...included_obj.attributes}
    }
  }
}
</script>